import { useState, useEffect } from "react";
import Quizz from "../components/Quizz";
import QuizzHeader from "../components/QuizzHeader";
import { retele } from "../utils/retele";
import { shuffle } from "../utils";
import useLocalStorage from "../hooks/useLocalStorage";

const Retele = () => {
  const [score, setScore] = useState(0);
  const [correctAnswersNumber, setCorrectAnswersNumber] = useState(0);
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
  const [quizzProgression, setQuizzProgression] = useState(0);
  const [shouldShuffle, setShouldShuffle] = useLocalStorage("shuffle", true);
  const [questions, setQuestions] = useState(
    shouldShuffle ? shuffle(retele) : retele
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    shouldShuffle ? setQuestions(shuffle(retele)) : setQuestions(retele);
  }, [shouldShuffle]);

  return (
    <>
      <QuizzHeader
        score={score}
        correctAnswersNumber={correctAnswersNumber}
        quizzProgression={quizzProgression}
        quizzLength={retele.length}
        shouldShuffle={shouldShuffle}
        setShouldShuffle={setShouldShuffle}
      />
      <div className="content">
        <Quizz
          questions={questions}
          quizzProgression={quizzProgression}
          setQuizzProgression={setQuizzProgression}
          setScore={setScore}
          setCorrectAnswersNumber={setCorrectAnswersNumber}
          showCorrectAnswer={showCorrectAnswer}
          setShowCorrectAnswer={setShowCorrectAnswer}
        />
      </div>
    </>
  );
};

export default Retele;
