import { useState, useEffect, useRef } from "react";
import Quizz from "../components/Quizz";
import QuizzHeader from "../components/QuizzHeader";
import { algoritmi } from "../utils/algoritmi";
import { shuffle } from "../utils";
import useLocalStorage from "../hooks/useLocalStorage";

const Algoritmi = () => {
  const [score, setScore] = useState(0);
  const [correctAnswersNumber, setCorrectAnswersNumber] = useState(0);
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
  const [quizzProgression, setQuizzProgression] = useState(0);
  const [shouldShuffle, setShouldShuffle] = useLocalStorage("shuffle", false);
  const [questions, setQuestions] = useState(
    shouldShuffle ? shuffle(algoritmi) : algoritmi
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    shouldShuffle ? setQuestions(shuffle(algoritmi)) : setQuestions(algoritmi);
    setShowCorrectAnswer(false);
  }, [shouldShuffle]);

  return (
    <>
      <QuizzHeader
        score={score}
        correctAnswersNumber={correctAnswersNumber}
        quizzProgression={quizzProgression}
        quizzLength={algoritmi.length}
        shouldShuffle={shouldShuffle}
        setShouldShuffle={setShouldShuffle}
      />
      <div className="content">
        <Quizz
          questions={questions}
          quizzProgression={quizzProgression}
          setQuizzProgression={setQuizzProgression}
          setScore={setScore}
          setCorrectAnswersNumber={setCorrectAnswersNumber}
          showCorrectAnswer={showCorrectAnswer}
          setShowCorrectAnswer={setShowCorrectAnswer}
        />
      </div>
    </>
  );
};

export default Algoritmi;
