import { v4 as uuidv4 } from "uuid";

export const baze = [
  {
    id: uuidv4(),
    image: "/images/baze/1.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/2.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/3.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/4.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/5.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/6.png",
    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/7.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/8.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/9.png",
    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/10.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/11.png",
    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/12.png",
    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/13.png",
    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/14.png",
    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/15.png",
    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/16.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/17.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/18.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/19.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/20.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/21.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/22.png",
    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/23.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/24.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/25.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/26.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/27.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/28.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/29.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/30.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/31.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/32.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/33.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/34.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/35.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/36.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/37.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/38.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/39.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/40.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/41.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/42.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/43.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/44.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/45.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/46.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/47.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/48.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/49.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/baze/50.png",

    type: "baze",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
];
