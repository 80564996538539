import { Logo } from "../components/icons";

const Index = () => {
  return (
    <div className="logoContainer">
      <Logo />
    </div>
  );
};

export default Index;
