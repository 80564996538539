import { useEffect, useState } from "react";
import Quizz from "../components/Quizz";
import QuizzHeader from "../components/QuizzHeader";
import { algoritmi } from "../utils/algoritmi";
import { baze } from "../utils/baze";
import { cloud } from "../utils/cloud";
import { comert } from "../utils/comert";
import { cplusplus } from "../utils/cplusplus";
import { criptografie } from "../utils/criptografie";
import { java } from "../utils/java";
import { procedurala } from "../utils/procedurala";
import { retele } from "../utils/retele";
import { sgbd } from "../utils/sgbd";
import { sisteme } from "../utils/sisteme";
import { tehnici } from "../utils/tehnici";
import { tehnologii } from "../utils/tehnologii";
import { shuffle } from "../utils";
import useLocalStorage from "../hooks/useLocalStorage";

const proportion = 3;
const defaultProportions = [
  {
    type: "algoritmi",
    proportion: 3,
  },
  {
    type: "baze",
    proportion: 3,
  },
  {
    type: "cloud",
    proportion: 2,
  },
  {
    type: "comert",
    proportion: 2,
  },
  {
    type: "cplusplus",
    proportion: 3,
  },
  {
    type: "criptografie",
    proportion: 3,
  },
  {
    type: "java",
    proportion: 3,
  },
  {
    type: "procedurala",
    proportion: 3,
  },
  {
    type: "retele",
    proportion: 3,
  },
  {
    type: "sgbd",
    proportion: 3,
  },
  {
    type: "sisteme",
    proportion: 3,
  },
  {
    type: "tehnici",
    proportion: 3,
  },
  {
    type: "tehnologii",
    proportion: 2,
  },
];

const getDomainType = (domain) => {
  switch (domain) {
    case "algoritmi":
      return algoritmi;
    case "baze":
      return baze;
    case "cloud":
      return cloud;
    case "comert":
      return comert;
    case "cplusplus":
      return cplusplus;
    case "criptografie":
      return criptografie;
    case "java":
      return java;
    case "procedurala":
      return procedurala;
    case "retele":
      return retele;
    case "sgbd":
      return sgbd;
    case "sisteme":
      return sisteme;
    case "tehnici":
      return tehnici;
    case "tehnologii":
      return tehnologii;
    default:
      return "";
  }
};

const Simulare = () => {
  const [score, setScore] = useState(0);
  const [correctAnswersNumber, setCorrectAnswersNumber] = useState(0);
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
  const [quizzProgression, setQuizzProgression] = useState(0);

  const [useCustomProportion, setUseCustomProportion] = useLocalStorage(
    "useCustomProportions",
    false
  );
  const [proportions, setProportions] = useLocalStorage(
    "proportions",
    defaultProportions
  );

  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (useCustomProportion === false) {
      setProportions(defaultProportions);
    }
  }, [useCustomProportion]);

  useEffect(() => {
    const getShuffledQuestions = () => {
      let questions = [];
      proportions.forEach((p) => {
        const domain = getDomainType(p.type);
        const shuffledQuestions = shuffle(domain).slice(0, p.proportion);
        questions = [...questions, ...shuffledQuestions];
      });
      return questions;
    };

    setQuestions(shuffle(getShuffledQuestions()));
  }, [proportions]);

  return (
    <>
      <QuizzHeader
        score={score}
        correctAnswersNumber={correctAnswersNumber}
        quizzProgression={quizzProgression}
        quizzLength={questions.length}
        useCustomProportion={useCustomProportion}
        defaultProportions={defaultProportions}
        setUseCustomProportion={setUseCustomProportion}
        proportions={proportions}
        setProportions={setProportions}
      />
      <div className="content">
        <Quizz
          questions={questions}
          quizzProgression={quizzProgression}
          setQuizzProgression={setQuizzProgression}
          setScore={setScore}
          setCorrectAnswersNumber={setCorrectAnswersNumber}
          showCorrectAnswer={showCorrectAnswer}
          setShowCorrectAnswer={setShowCorrectAnswer}
        />
      </div>
    </>
  );
};

export default Simulare;
