import { v4 as uuidv4 } from "uuid";

export const criptografie = [
  {
    id: uuidv4(),
    image: "/images/criptografie/1.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/2.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/3.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/4.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/5.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/6.png",
    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/7.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/8.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/9.png",
    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/10.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/11.png",
    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/12.png",
    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/13.png",
    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/14.png",
    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/15.png",
    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/16.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/17.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/18.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/19.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/20.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/21.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/22.png",
    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/23.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/24.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/25.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/26.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/27.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/28.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/29.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/30.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/31.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/32.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/33.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/34.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/35.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/36.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/37.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/38.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/39.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/40.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/41.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/42.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/43.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/44.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/45.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/46.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/47.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/48.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/49.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/criptografie/50.png",

    type: "criptografie",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
];
