import React from "react";
import { useLocation } from "react-router-dom";
import SetProportions from "./SetProportions";

const QuizzHeader = (props) => {
  const {
    score,
    correctAnswersNumber,
    quizzProgression,
    quizzLength,
    shouldShuffle,
    setShouldShuffle,
    useCustomProportion,
    defaultProportions,
    setUseCustomProportion,
    proportions,
    setProportions,
  } = props;

  const location = useLocation();

  return (
    <header className="headerScore">
      <div className="container headerScore">
        <div className="headerScoreText">
          Chestionar completat {quizzProgression} / {quizzLength}
        </div>
        <div className="headerScoreText">
          Intrebări corecte {correctAnswersNumber} / {quizzLength}
        </div>
        <div className="headerScoreText">Scor {score} / 100%</div>
        {location.pathname !== "/boloboc/simulare" && (
          <div className="headerScoreText">
            <label className="randomButton">
              Afișează aleatoriu
              <input
                type="checkbox"
                checked={shouldShuffle}
                onChange={() => setShouldShuffle(!shouldShuffle)}
              />
            </label>
          </div>
        )}
        {location.pathname === "/boloboc/simulare" && (
          <div>
            <SetProportions
              useCustomProportion={useCustomProportion}
              defaultProportions={defaultProportions}
              setUseCustomProportion={setUseCustomProportion}
              proportions={proportions}
              setProportions={setProportions}
            />
          </div>
        )}
      </div>
    </header>
  );
};

export default QuizzHeader;
