import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import "../App.css";
import {
  CheckIcon,
  CloseIcon,
  ChatAlertIcon,
  ArrowUpIcon,
  ArrowDownIcon,
} from "./icons";
import { Button } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import { useLocation, useNavigate } from "react-router-dom";

function percentage(partialValue, totalValue) {
  return (100 * partialValue) / totalValue;
}

const Quizz = (props) => {
  const {
    questions,
    setQuizzProgression,
    setScore,
    setCorrectAnswersNumber,
    showCorrectAnswer,
    setShowCorrectAnswer,
    quizzProgression,
  } = props;

  const [quizz, setQuizz] = useState([]);
  const [finished, setFinished] = useState(false);
  const [incorrectQuestions, setIncorrectQuestions] = useState([]);
  const [nextQuestion, setNextQuestion] = useState(null);
  const [previousQuestion, setPreviousQuestion] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [goToProblem, setGoToProblem] = useState(true);

  const getToProblem = () => {
    setGoToProblem(false);

    const currentIndex = incorrectQuestions.indexOf(currentQuestion);
    const nextCurrent = incorrectQuestions[currentIndex - 1];

    // console.log("");
    // console.log("currentIndex", currentIndex);
    // console.log("currentQuestion", currentQuestion);
    // console.log("nextQuestion", nextQuestion);
    // console.log("previousQuestion", previousQuestion);
  };

  const getNextLocation = () => {
    const total = incorrectQuestions.length - 1;

    const currentIndex = incorrectQuestions.indexOf(currentQuestion);

    const nextCurrent = incorrectQuestions[currentIndex - 1];
    const nextQuestion = incorrectQuestions[currentIndex - 2];
    const nextPreviousQuestion = currentQuestion;

    setPreviousQuestion(nextPreviousQuestion);
    setCurrentQuestion(nextCurrent);
    setNextQuestion(nextQuestion);

    if (currentIndex === 1) {
      setNextQuestion(null);
    }
    if (nextCurrent === total) {
      setPreviousQuestion(null);
    }

    // console.log("");
    // console.log("next");
    // console.log("currentIndex", currentIndex);
    // console.log("currentQuestion", currentQuestion);
    // console.log("nextCurrent", nextCurrent);
    // console.log("nextQuestion", nextQuestion);
    // console.log("nextPreviousQuestion", nextPreviousQuestion);
  };

  const getPreviousLocation = () => {
    const total = incorrectQuestions.length - 1;

    const currentIndex = incorrectQuestions.indexOf(currentQuestion);

    const nextCurrent = incorrectQuestions[currentIndex + 1];
    const nextQuestion = currentQuestion;
    const nextPreviousQuestion = incorrectQuestions[currentIndex + 2];

    setNextQuestion(nextQuestion);
    setCurrentQuestion(nextCurrent);
    setPreviousQuestion(nextPreviousQuestion);

    if (nextCurrent === total) {
      setPreviousQuestion(null);
    }

    // console.log("");
    // console.log("previous");
    // console.log("currentIndex", currentIndex);
    // console.log("currentQuestion", currentQuestion);
    // console.log("nextCurrent", nextCurrent);
    // console.log("nextQuestion", nextQuestion);
    // console.log("nextPreviousQuestion", nextPreviousQuestion);
  };

  const navigate = useNavigate();
  const location = useLocation();

  let debugMode = false;

  useEffect(() => {
    incrementQuizzProgression();
  }, [quizz]);

  useEffect(() => {
    setQuizz(questions);
  }, [questions]);

  const handleOnChange = (questionId, answerId) => {
    const newQ = quizz.map((q) => {
      if (q.id === questionId) {
        if (q.givenAnswer.includes(answerId)) {
          return {
            ...q,
            givenAnswer: q.givenAnswer.filter((a) => a !== answerId),
          };
        } else {
          return {
            ...q,
            givenAnswer: [...q.givenAnswer, answerId],
          };
        }
      }
      return q;
    });

    setQuizz(newQ);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let correctedQuizz = [];
    quizz.forEach((q) => {
      const questionStatus = questionIsCorrect(q);
      correctedQuizz.push({
        ...q,
        isCorrect: questionStatus,
      });
    });

    let incorrectList = correctedQuizz.filter((q) => !q.isCorrect);
    setIncorrectQuestions(incorrectList);
    setQuizz(correctedQuizz);
    setFinished(true);

    if (incorrectList.length > 0) {
      const items = [...incorrectList];
      const lastItem = items[items.length - 1];
      const secondLast = items[items.length - 2];

      setNextQuestion(secondLast);
      setCurrentQuestion(lastItem);
    }

    const correctAnswers = correctedQuizz.filter((q) => q.isCorrect).length;
    setScore(Math.round(percentage(correctAnswers, correctedQuizz.length)));
    setCorrectAnswersNumber(correctAnswers);
    setShowCorrectAnswer(true);
  };

  const questionIsCorrect = (question) => {
    let correctAnswers = 0;
    question.answers.forEach((answer) => {
      return answer.isCorrect ? correctAnswers++ : null;
    });

    const isCorrect = (givenAnswer) => {
      const findAnswer = question.answers.find(
        (answer) => givenAnswer === answer.id
      );

      return findAnswer.isCorrect === true;
    };

    if (
      question.givenAnswer.length > 0 &&
      question.givenAnswer.length === correctAnswers
    ) {
      return question.givenAnswer.every(isCorrect);
    } else {
      return false;
    }
  };

  const incrementQuizzProgression = () => {
    let progression = 0;
    quizz.forEach((q) => {
      if (q.givenAnswer.length > 0) {
        progression++;
      }
    });
    setQuizzProgression(progression);
  };

  const getLetter = (number) => {
    if (number === 0) {
      return "A";
    }
    if (number === 1) {
      return "B";
    }
    if (number === 2) {
      return "C";
    }
    if (number === 3) {
      return "D";
    }
    if (number === 4) {
      return "E";
    }
    if (number === 5) {
      return "F";
    }
    if (number === 6) {
      return "G";
    }
    if (number === 7) {
      return "H";
    }
    if (number === 8) {
      return "I";
    }
    if (number === 9) {
      return "J";
    }
    if (number === 10) {
      return "K";
    }
  };

  const handleTryAgain = () => {
    navigate(`${location.pathname}`, { replace: true });
    window.location.reload();
    window.scrollTo(0, 0);
  };

  const check = (param) => {
    if (param === null) {
      return true;
    }
    if (param === undefined) {
      return true;
    }
  };

  return (
    <>
      {quizz.map((question, index) => {
        return (
          <div
            key={question.id}
            id={question.id}
            className={classNames("container questionContainer", {
              ["quizzCorrect"]: question.isCorrect,
              ["showBackground"]: showCorrectAnswer,
            })}
          >
            <div className="titleContainer">
              <span className="questionIndex">{index + 1}.</span>
              <div className="title">
                <img src={question.image} alt="quizz" />
              </div>
            </div>
            {question.answers.map((answer, index) => {
              return (
                <div
                  className={classNames("optionContainer", {
                    ["isCorrect"]: answer.isCorrect && showCorrectAnswer,
                  })}
                  key={index}
                >
                  <label>
                    <input
                      type="checkbox"
                      checked={question.givenAnswer.includes(answer.id)}
                      onChange={() => handleOnChange(question.id, answer.id)}
                    />
                    <div className="option">
                      <span>{getLetter(index)})</span>
                    </div>
                    {showCorrectAnswer && (
                      <div>
                        {answer.isCorrect ? (
                          <CheckIcon
                            style={{ fill: "#1ED266", marginLeft: 10 }}
                          />
                        ) : (
                          <CloseIcon
                            style={{ fill: "#E5143A", marginLeft: 10 }}
                          />
                        )}
                      </div>
                    )}
                  </label>
                </div>
              );
            })}
            <div className="spacer"></div>
          </div>
        );
      })}

      <div className="bottomContainer">
        {finished ? (
          <>
            <div className="startAgainContainer">
              <Button
                type="submit"
                variant="primary"
                className="submitButton"
                size="lg"
                onClick={handleTryAgain}
              >
                Începe din nou
              </Button>
              {goToProblem && incorrectQuestions.length > 0 && (
                <HashLink
                  to={`${location.pathname}#${currentQuestion?.id}`}
                  className="goToProblemButtonContainer"
                >
                  <Button
                    onClick={getToProblem}
                    size="lg"
                    className="goToProblemButton"
                    variant="danger"
                  >
                    Mergi la problemă
                  </Button>
                </HashLink>
              )}

              {!goToProblem && incorrectQuestions.length > 1 && (
                <>
                  <HashLink
                    to={`${location.pathname}#${nextQuestion?.id}`}
                    className={classNames({
                      ["noInteraction"]: check(nextQuestion),
                    })}
                  >
                    <Button
                      onClick={getNextLocation}
                      size="lg"
                      variant="danger"
                      className="goToProblemButton"
                      disabled={nextQuestion === null || undefined}
                    >
                      <ArrowUpIcon style={{ fill: "#FFFFFF" }} />
                    </Button>
                  </HashLink>

                  <HashLink
                    to={`${location.pathname}#${previousQuestion?.id}`}
                    className={classNames({
                      ["noInteraction"]: check(previousQuestion),
                    })}
                  >
                    <Button
                      onClick={getPreviousLocation}
                      size="lg"
                      variant="danger"
                      className="goToProblemButton"
                      disabled={
                        previousQuestion === null
                          ? true
                          : false || previousQuestion === undefined
                          ? true
                          : false
                      }
                    >
                      <ArrowDownIcon style={{ fill: "#FFFFFF" }} />
                    </Button>
                  </HashLink>
                </>
              )}
            </div>

            <div className="contact">
              <a
                href="mailto:facutlaboloboc@gmail.com?subject=Am%20gasit%20o%20problema%20la%20intrebarea..."
                target="_blank"
                rel="noreferrer"
              >
                <ChatAlertIcon style={{ fill: "#E5143A" }} /> Cine va lucrat
                aicia?
              </a>
            </div>
          </>
        ) : (
          <>
            <Button
              type="submit"
              variant="primary"
              className="submitButton"
              size="lg"
              onClick={(e) => onSubmit(e)}
            >
              Trimite {quizzProgression} / {quizz.length}
            </Button>

            <div className="contact">
              <a
                href="mailto:facutlaboloboc@gmail.com?subject=Am%20gasit%20o%20problema%20la%20intrebarea..."
                target="_blank"
                rel="noreferrer"
              >
                <ChatAlertIcon style={{ fill: "#E5143A" }} /> Cine va lucrat
                aicia?
              </a>
            </div>
          </>
        )}
        {debugMode && (
          <Button
            type="submit"
            variant="primary"
            className="submitButton"
            onClick={(e) => onSubmit(e)}
          >
            Trimite {quizzProgression} / {quizz.length}
          </Button>
        )}
      </div>
    </>
  );
};

export default Quizz;
