import * as React from "react";

export const CheckIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M21 7 9 19l-5.5-5.5 1.41-1.41L9 16.17 19.59 5.59 21 7Z" />
  </svg>
);

export const CloseIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41Z" />
  </svg>
);

export const EmailIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M12 15c.81 0 1.5-.3 2.11-.89.59-.61.89-1.3.89-2.11 0-.81-.3-1.5-.89-2.11C13.5 9.3 12.81 9 12 9c-.81 0-1.5.3-2.11.89C9.3 10.5 9 11.19 9 12c0 .81.3 1.5.89 2.11.61.59 1.3.89 2.11.89m0-13c2.75 0 5.1 1 7.05 2.95C21 6.9 22 9.25 22 12v1.45c0 1-.35 1.85-1 2.55-.7.67-1.5 1-2.5 1-1.2 0-2.19-.5-2.94-1.5-1 1-2.18 1.5-3.56 1.5-1.37 0-2.55-.5-3.54-1.46C7.5 14.55 7 13.38 7 12c0-1.37.5-2.55 1.46-3.54C9.45 7.5 10.63 7 12 7c1.38 0 2.55.5 3.54 1.46C16.5 9.45 17 10.63 17 12v1.45c0 .41.16.77.46 1.08.3.31.65.47 1.04.47.42 0 .77-.16 1.07-.47.3-.31.43-.67.43-1.08V12c0-2.19-.77-4.07-2.35-5.65S14.19 4 12 4c-2.19 0-4.07.77-5.65 2.35S4 9.81 4 12c0 2.19.77 4.07 2.35 5.65S9.81 20 12 20h5v2h-5c-2.75 0-5.1-1-7.05-2.95C3 17.1 2 14.75 2 12s1-5.1 2.95-7.05C6.9 3 9.25 2 12 2Z" />
  </svg>
);

export const ChatAlertIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M12 3c5.5 0 10 3.58 10 8s-4.5 8-10 8c-1.24 0-2.43-.18-3.53-.5C5.55 21 2 21 2 21c2.33-2.33 2.7-3.9 2.75-4.5C3.05 15.07 2 13.13 2 11c0-4.42 4.5-8 10-8m-1 11v2h2v-2h-2m0-2h2V6h-2v6Z" />
  </svg>
);

export const Logo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={74}
    height={71}
    viewBox="0 0 74 71"
    {...props}
  >
    <path
      d="M38.4 18c0-1.6.6-3.9 3.3-6.1 1-.8 1.5-1.6 1.5-2.4 0-1.6-1.6-3.1-1.6-3.1-1-.9-1.1-2.4-.2-3.4.9-1 2.4-1.1 3.4-.2.3.3 3.3 3.1 3.2 6.9 0 1.6-.6 3.9-3.3 6.1-1 .8-1.5 1.6-1.5 2.4 0 1.3 1.2 2.7 1.6 3.1 1 .9 1.1 2.4.2 3.4-.5.5-1.1.8-1.8.8-.6 0-1.2-.2-1.6-.6-.4-.3-3.3-3.1-3.2-6.9zm-9.1 6.9c.4.4 1 .6 1.6.6.7 0 1.4-.3 1.8-.8.4-.5.6-1.1.6-1.8 0-.6-.3-1.2-.8-1.7-.4-.4-1.6-1.8-1.6-3.1 0-.8.5-1.6 1.5-2.4 2.7-2.2 3.3-4.5 3.3-6.1.1-3.8-2.9-6.6-3.2-6.9-.9-.8-2.5-.7-3.4.3-.4.5-.6 1.1-.6 1.8 0 .6.3 1.2.8 1.7 0 0 1.6 1.5 1.6 3.1 0 .8-.5 1.6-1.5 2.4-2.7 2.2-3.3 4.5-3.3 6.1-.1 3.7 2.9 6.5 3.2 6.8zm40.8 19.2c0 5.7-4.6 10.3-10.3 10.3h-4.6c-2 3.8-4.9 7.1-8.5 9.5h8.8c1.3 0 2.4 1.1 2.4 2.4 0 1.3-1.1 2.4-2.4 2.4H6.3c-1.3 0-2.4-1.1-2.4-2.4 0-1.3 1.1-2.4 2.4-2.4h8.8C8 59.1 3.9 51.4 3.9 43V32.8c0-1.3 1.1-2.4 2.4-2.4h49.3c1.3 0 2.4 1.1 2.4 2.4v.9h1.8c5.7 0 10.3 4.7 10.3 10.4zm-16.9-8.8H8.7V43c0 11.5 10 20.9 22.2 20.9 12.3 0 22.2-9.4 22.2-20.9v-7.7zm12.1 8.8c0-3-2.4-5.5-5.5-5.5H58V43c0 2.2-.3 4.4-.9 6.5h2.7c3 0 5.5-2.4 5.5-5.4zM17.1 24.9c.4.4 1 .6 1.6.6.7 0 1.4-.3 1.8-.8.4-.5.6-1.1.6-1.8 0-.6-.3-1.2-.8-1.7-.5-.4-1.6-1.8-1.6-3.1 0-.8.5-1.6 1.5-2.4 2.7-2.2 3.3-4.5 3.3-6.1.1-3.8-2.9-6.6-3.2-6.9-1-.9-2.5-.8-3.4.2-.4.5-.6 1.1-.6 1.8 0 .6.3 1.2.8 1.7.5.4 1.6 1.8 1.6 3.1 0 .8-.5 1.6-1.5 2.4-2.7 2.2-3.3 4.5-3.3 6.1-.1 3.8 2.8 6.6 3.2 6.9z"
      fill="#443caa"
    />
  </svg>
);

export const ArrowUpIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M13 20h-2V8l-5.5 5.5-1.42-1.42L12 4.16l7.92 7.92-1.42 1.42L13 8v12Z" />
  </svg>
);

export const ArrowDownIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M11 4h2v12l5.5-5.5 1.42 1.42L12 19.84l-7.92-7.92L5.5 10.5 11 16V4Z" />
  </svg>
);
