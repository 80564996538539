import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

export default function SetProportions(props) {
  const {
    useCustomProportion,
    defaultProportions,
    setUseCustomProportion,
    proportions,
    setProportions,
  } = props;
  const [show, setShow] = useState(false);

  const handleClose = () => {
    if (useCustomProportion === false) {
      setEnableCustomProportions(false);
    }
    setProportionsInput(proportions);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const [proportionsInput, setProportionsInput] = useState(proportions);
  const [enableCustomProportions, setEnableCustomProportions] =
    useState(useCustomProportion);

  const handleChange = (id, value) => {
    setProportionsInput(
      proportionsInput.map((item) => {
        if (item.type === id) {
          return { ...item, proportion: value };
        }
        return item;
      })
    );
  };

  const handleSave = () => {
    setProportions(proportionsInput);
    setUseCustomProportion(enableCustomProportions);
    setShow(false);
  };

  const handleReset = () => {
    setProportionsInput(defaultProportions);
    setUseCustomProportion(false);
    setEnableCustomProportions(false);
  };

  return (
    <>
      <Button
        variant="secondary"
        style={{ opacity: 0.5 }}
        onClick={handleShow}
        size="sm"
      >
        Setări
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Proporții</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="customProportionsCheckboxContainer">
            <div className="customProportionsCheckbox">
              <input
                type="checkbox"
                id="useCustomProportions"
                value={useCustomProportion}
                checked={enableCustomProportions}
                onChange={() =>
                  setEnableCustomProportions(!enableCustomProportions)
                }
              />

              <label className="settingsLabel" htmlFor="useCustomProportions">
                Utilizează proporții personalizate
              </label>
            </div>
          </div>
          <form className="form-floating">
            {proportionsInput?.map((item) => {
              return (
                <div key={item.type}>
                  <label className="settingsLabel" htmlFor={item.type}>
                    {item.type}
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id={item.type}
                    placeholder={item.proportion}
                    value={item.proportion}
                    onChange={(e) => handleChange(item.type, e.target.value)}
                    disabled={!enableCustomProportions}
                  />
                </div>
              );
            })}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleReset}>
            Resetează
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            închide
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Salvează
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
