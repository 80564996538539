import { v4 as uuidv4 } from "uuid";

export const tehnici = [
  {
    id: uuidv4(),
    image: "/images/tehnici/1.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/2.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/3.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/4.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/5.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/6.png",
    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/7.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/8.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/9.png",
    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/10.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/11.png",
    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/12.png",
    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/13.png",
    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/14.png",
    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/15.png",
    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/16.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/17.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/18.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/19.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/20.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/21.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/22.png",
    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/23.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/24.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/25.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/26.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/27.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/28.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/29.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/30.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/31.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/32.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/33.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/34.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/35.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/36.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/37.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/38.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/39.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/40.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/41.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/42.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/43.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/44.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/45.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/46.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/47.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/48.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/49.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/tehnici/50.png",

    type: "tehnici",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
];
