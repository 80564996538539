import { v4 as uuidv4 } from "uuid";

export const retele = [
  {
    id: uuidv4(),
    image: "/images/retele/1.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/2.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/3.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/4.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/5.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/6.png",
    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/7.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/8.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/9.png",
    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/10.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/11.png",
    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/12.png",
    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/13.png",
    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/14.png",
    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/15.png",
    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/16.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/17.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/18.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/19.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/20.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/21.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/22.png",
    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/23.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/24.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/25.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/26.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/27.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/28.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/29.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/30.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/31.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/32.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/33.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/34.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/35.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/36.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/37.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/38.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/39.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/40.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/41.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/42.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/43.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/44.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/45.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/46.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/47.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/48.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/49.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/50.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/51.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/52.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/53.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/54.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/55.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },

  {
    id: uuidv4(),
    image: "/images/retele/56.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },

  {
    id: uuidv4(),
    image: "/images/retele/57.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/58.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/59.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/retele/60.png",

    type: "retele",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
];
