import Simulare from "./pages/Simulare";
import Algoritmi from "./pages/Algoritmi";
import Baze from "./pages/Baze";
import Cloud from "./pages/Cloud";
import Comert from "./pages/Comert";
import Cplusplus from "./pages/Cplusplus";
import Criptografie from "./pages/Criptografie";
import Java from "./pages/Java";
import Procedurala from "./pages/Procedurala";
import Retele from "./pages/Retele";
import Sgbd from "./pages/Sgbd";
import Sisteme from "./pages/Sisteme";
import Tehnici from "./pages/Tehnici";
import Tehnologii from "./pages/Tehnologii";
import Index from "./pages/Index";
import classNames from "classnames";
import { Nav } from "react-bootstrap";

import { Route, Routes, Navigate, useLocation } from "react-router-dom";

import "./App.css";

function App() {
  const location = useLocation();

  return (
    <div
      className={classNames("app", {
        ["rootPage"]: location.pathname === "/",
      })}
    >
      {location.pathname !== "/" && (
        <header className="headerNavigation">
          <Nav variant="pills" defaultActiveKey={location.pathname}>
            <Nav.Item>
              <Nav.Link
                href="/boloboc/simulare"
                className={classNames({
                  ["active"]: location.pathname === "/boloboc/simulare",
                })}
              >
                Simulare
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/boloboc/algoritmi">Algoritmi</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/boloboc/baze">Baze</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/boloboc/cloud">Cloud</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/boloboc/comert">Comerț</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/boloboc/cplusplus">Cplusplus</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/boloboc/criptografie">Criptografie</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/boloboc/java">Java</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/boloboc/procedurala">Procedurală</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/boloboc/retele">Rețele</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/boloboc/sgbd">Sgbd</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/boloboc/sisteme">Sisteme</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/boloboc/tehnici">Tehnici</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/boloboc/tehnologii">Tehnologii</Nav.Link>
            </Nav.Item>
          </Nav>
        </header>
      )}

      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/boloboc/simulare" element={<Simulare />} />
        <Route path="/boloboc/algoritmi" element={<Algoritmi />} />
        <Route path="/boloboc/baze" element={<Baze />} />
        <Route path="/boloboc/cloud" element={<Cloud />} />
        <Route path="/boloboc/comert" element={<Comert />} />
        <Route path="/boloboc/cplusplus" element={<Cplusplus />} />
        <Route path="/boloboc/criptografie" element={<Criptografie />} />
        <Route path="/boloboc/java" element={<Java />} />
        <Route path="/boloboc/procedurala" element={<Procedurala />} />
        <Route path="/boloboc/retele" element={<Retele />} />
        <Route path="/boloboc/sgbd" element={<Sgbd />} />
        <Route path="/boloboc/sisteme" element={<Sisteme />} />
        <Route path="/boloboc/tehnici" element={<Tehnici />} />
        <Route path="/boloboc/tehnologii" element={<Tehnologii />} />
        <Route path="/boloboc" element={<Navigate to="/boloboc/simulare" />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}

export default App;
