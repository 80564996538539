import { v4 as uuidv4 } from "uuid";

export const procedurala = [
  {
    id: uuidv4(),
    image: "/images/procedurala/1.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/2.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/3.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/4.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/5.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/6.png",
    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/7.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/8.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/9.png",
    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/10.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/11.png",
    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/12.png",
    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/13.png",
    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/14.png",
    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/15.png",
    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/16.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/17.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/18.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/19.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/20.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/21.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/22.png",
    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/23.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/24.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/25.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/26.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/27.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/28.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/29.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/30.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/31.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/32.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/33.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/34.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/35.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/36.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/37.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/38.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/39.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/40.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/41.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/42.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/43.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/44.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/45.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/46.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/47.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/48.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/49.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
  {
    id: uuidv4(),
    image: "/images/procedurala/50.png",

    type: "procedurala",
    givenAnswer: [],
    answers: [
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: true,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
      {
        id: uuidv4(),
        isCorrect: false,
      },
    ],
  },
];
